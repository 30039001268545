import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons mb-0">
        <li>
          <a href="https://www.instagram.com/balancetonhacker/" className="icon fa-instagram">
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
      <ul className="list-unstyled">
        <li>@balancetonhacker</li>
        <li>#balancetonhacker</li>
      </ul>
    </div>
  </div>
)

export default Footer
