import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Checker = () => (
  <header id="header">
    <div className="inner">
      <a href="/" className="image avatar">
        <img src={avatar} alt="" />
      </a>
      <h2>
        <a href="/#main"><span className="icon fa-home"></span>&nbsp;ACCUEIL</a>
      </h2>
      <h2>
        <a href="/blog#main"><span className="icon fa-bookmark"></span>&nbsp;BLOG</a>
      </h2>
      <h2>
        <a href="mailto:thesaint_templar@protonmail.com"><span className="icon fa-envelope"></span>&nbsp;CONTACT</a>
      </h2>
      <h2>
        <a href="/checker"><span className="icon fa-crosshairs"></span>&nbsp;FACEBOOK CHECKER</a>
      </h2>
    </div>
    <Footer />
  </header>
)

export default Checker
